/*------------------------------------
  File for your custom SCSS style
------------------------------------*/
.text-orange
{
  color: $orange;
}

.text-purple
{
  color: $purple;
}