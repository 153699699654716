/*------------------------------------
  Badge
------------------------------------*/

.badge {
  line-height: normal;

  .legend-indicator {
    margin-right: $badge-legend-indicator-margin-x;
  }
}