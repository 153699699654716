/*------------------------------------
  Form Select
------------------------------------*/

.form-select {
  cursor: pointer;
}

.form-select-borderless {
  border-width: 0;
}