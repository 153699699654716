// Front Default Color Variables
// =========================================================

$root-path:                                                 "../";



// Grays
$gray-100:                                                  #f9fafc;
$gray-200: #f4f4f4;
$gray-300: #e7eaf3;
$gray-400:                                                  #bdc5d1;
$gray-500:                                                  #97a4af;
$gray-600:                                                  #8c98a4;
$gray-700:                                                  #677788;
$gray-800:                                                  #71869d;
$gray-900:                                                  #1e2022;

// Colors
$white:                                                     #fff;
$dark:                                                      #132144;
$blue:                                                      #377dff;
$teal:                                                      #00c9a7;
$cyan:                                                      #09a5be;
$yellow:                                                    #f5ca99;
$red:                                                       #ed4c78;

// Theme Colors
$primary:                                                   $blue;
$secondary:                                                 $gray-800;
$success:                                                   $teal;
$info:                                                      $cyan;
$warning:                                                   $yellow;
$danger:                                                    $red;
$dark:                                                      $dark;

// Theme Soft Colors
$primary-soft:                                              rgba($primary, .1);
$secondary-soft:                                            rgba($secondary, .1);
$success-soft:                                              rgba($success, .1);
$info-soft:                                                 rgba($info, .1);
$warning-soft:                                              rgba($warning, .1);
$danger-soft:                                               rgba($danger, .1);
$light-soft:                                                rgba($gray-100, .1);
$dark-soft:                                                 rgba($dark, .1);