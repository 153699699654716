/*------------------------------------
  Form Check Switch
------------------------------------*/

.form-check-switch {
  display: inline-block;
  padding-left: 0;
  margin-bottom: 0;

  .form-check-input {
    display: none;
  }

  .form-check-label {
    cursor: pointer;
    color: $form-check-switch-label-color;
    background-color: $form-check-switch-label-bg-color;
    border: $form-check-switch-label-border-width solid $form-check-switch-label-border-color;
  }
  
  .form-check-input:checked ~ .form-check-label {
    color: $form-check-switch-label-active-color;
    border-style: solid;
    background-color: $form-check-switch-label-active-bg-color;
  }

  .form-check-input:disabled ~ .form-check-label {
    color: $form-check-switch-label-disabled-color;
    background-color: $form-check-switch-label-disabled-bg-color;
  }
}