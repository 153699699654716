// user-variables.scss
// Use this to overwrite Bootstrap and Front variables
//


// Example of a variable override to change Front's primary color and font size
// Remove the "//" to comment it in and see it in action!
 $primary: #51307C;
 //$font-size-base: .875rem;



// The color of the SVG loader should be modified manually.
// See assets/svg/components/circle-preloader.svg



// Grays
$gray-100:                                                  #f9fafc;
$gray-200:                                                  #f8fafd;
$gray-300:                                                  #e7eaf3;
$gray-400:                                                  #bdc5d1;
$gray-500:                                                  #97a4af;
$gray-600:                                                  #8c98a4;
$gray-700:                                                  #677788;
$gray-800:                                                  #343a40;
$gray-900:                                                  #1e2022;

$blue:    #0d6efd !default;
$bluebase:    #0d6efd !default;
$indigo:  #6610f2 !default;
$purple:  #51307C !default;
$pink:    #d63384 !default;
$red:     #dc3545 !default;
$orange:  #EB6609 !default;
$yellow:  #ffc107 !default;
$green:   #198754 !default;
$teal:    #20c997 !default;
$cyan:    #0dcaf0 !default;

$link-color:                                                $orange !default;

// Colors
$white:                                                     #fff;
$dark:                                                      #132144;
$blue: #51307C;
$teal:                                                      #198754;
$cyan:                                                      #09a5be;
$yellow:                                                    #ffc107;
$red:                                                       #dc3545;

// Theme Colors
$primary:       $blue !default;
$secondary:     $gray-600 !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $orange !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-900 !default;

// Theme Soft Colors
$primary-soft:                                              rgba($primary, .1);
$secondary-soft:                                            rgba($secondary, .1);
$success-soft:                                              rgba($success, .1);
$info-soft:                                                 rgba($info, .1);
$warning-soft:                                              rgba($warning, .1);
$danger-soft:                                               rgba($danger, .1);
$light-soft:                                                rgba($gray-100, .1);
$dark-soft:                                                 rgba($dark, .1);



//-------------------

$border-color:                                              $gray-300;
$border-radius:               .275rem !default;


$form-label-color:                                          $gray-800 !default;
$form-label-margin-bottom:                                  .1rem !default;
