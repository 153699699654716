/*------------------------------------
  Flatpickr
------------------------------------*/

.flatpickr-custom {
  position: relative;
  width: 100% !important;

  .flatpickr-calendar {
    top: $input-height !important;
    left: 0 !important;
    width: auto;
  }
}

.form-control[readonly],
.flatpickr-custom-form-control[readonly] {
  background-color: transparent;
}

.flatpickr-calendar {
  width: $flatpickr-width;
  padding: $flatpickr-padding-y $flatpickr-padding-x;
  box-shadow: $flatpickr-box-shadow;
  background-color: $flatpickr-bg-color;
  @include border-radius($flatpickr-border-radius);
  margin-top: $flatpickr-pseudo-border-width;

  &::before {
    left: $flatpickr-pseudo-left-offset;
    border-width: $flatpickr-pseudo-border-width;
    margin: 0 $flatpickr-pseudo-border-width;
  }

  &.arrowTop::before {
    border-bottom-color: $flatpickr-pseudo-border-color;
  }

  &::after {
    display: none;
  }

  &.animate.open {
    animation: $flatpickr-active-open-animation;
  }

  .flatpickr-months {
    align-items: center;
    margin-bottom: $flatpickr-months-margin-y;
  }

  .flatpickr-current-month,
  .flatpickr-prev-month,
  .flatpickr-next-month {
    padding: 0;
  }

  .flatpickr-current-month {
    display: flex;
    align-items: center;
    width: auto;
  }

  .flatpickr-prev-month,
  .flatpickr-next-month {
    position: static;
    color: $flatpickr-prev-next-btn-icon-color;
    width: $flatpickr-prev-next-btn-icon-width;
    height: $flatpickr-prev-next-btn-icon-height;
    line-height: $flatpickr-prev-next-btn-icon-line-height;
    background-color: $flatpickr-prev-next-btn-icon-bg-color;
    @include border-radius($flatpickr-prev-next-btn-icon-border-radius);

    &:hover {
      color: $flatpickr-prev-next-btn-icon-hover-color;
      background-color: $flatpickr-prev-next-btn-icon-hover-bg-color;
    }
  }

  .flatpickr-weekday {
    color: $flatpickr-weekday-color;
    text-transform: uppercase;
    font-weight: $flatpickr-weekday-font-weight;
  }

  .rangeMode .flatpickr-day {
    margin-top: $flatpickr-day-rangeMode-margin-top-offset;
  }

  .flatpickr-monthDropdown-months {
    color: $flatpickr-month-dropdown-color;
    font-size: $flatpickr-month-dropdown-font-size;
    font-weight: $flatpickr-months-dropdown-font-weight;

    &:hover {
      background-color: $flatpickr-month-dropdown-hover-bg-color;
    }
  }

  .flatpickr-current-month input {
    &.cur-year {
      color: $flatpickr-months-and-year-color;
      font-size: $flatpickr-months-and-year-font-size;
      font-weight: $flatpickr-months-and-year-font-weight;
    }
  }

  .flatpickr-day {
    color: $flatpickr-day-color;
    border: none;

    &:focus,
    &:hover {
      color: $flatpickr-day-hover-color;
      background-color: $flatpickr-day-hover-bg-color;
    }

    &.selected,
    &.startRange,
    &.endRange,
    &.selected.inRange,
    &.startRange.inRange,
    &.endRange.inRange,
    &.selected:focus,
    &.startRange:focus,
    &.endRange:focus,
    &.selected:hover,
    &.startRange:hover,
    &.endRange:hover,
    &.selected.prevMonthDay,
    &.startRange.prevMonthDay,
    &.endRange.prevMonthDay,
    &.selected.nextMonthDay,
    &.startRange.nextMonthDay,
    &.endRange.nextMonthDay {
      color: $flatpickr-selected-end-start-range-color;
      background-color: $flatpickr-selected-end-start-range-bg-color;
      border-color: $flatpickr-selected-end-start-range-border-color;
    }

    &.inRange {
      color: $flatpickr-day-inrange-color;
      background-color: $flatpickr-day-inrange-bg-color;
      box-shadow: $flatpickr-day-inrange-box-shadow;
    }

    &.inRange,
    &.selected {
      &.prevMonthDay,
      &.nextMonthDay {
        &,
        &:focus,
        &:hover {
          color: $flatpickr-day-in-range-selected-days-hover-color;
          border-color: $flatpickr-day-in-range-selected-days-hover-border-color;
          background-color: $flatpickr-day-in-range-selected-days-hover-bg-color;
        }
      }
    }

    &.today {
      color: $flatpickr-day-today-color;
      border-color: $flatpickr-day-today-border-color;
      background-color: $flatpickr-day-today-bg-color;
    }

    &.prevMonthDay,
    &.nextMonthDay {
      color: $flatpickr-day-prev-and-next-month-day-color;

      &:focus,
      &:hover {
        color: $flatpickr-day-prev-and-next-month-day-hover-color;
        background-color: $flatpickr-day-prev-and-next-month-day-hover-bg-color;
        border-color: $flatpickr-day-prev-and-next-month-day-hover-border-color;
      }
    }

    &.disabled {
      color: $flatpickr-day-disabled-color;

      &:hover {
        color: $flatpickr-day-disabled-hover-color;
        background-color: $flatpickr-day-disabled-hover-bg-color;
        border-color: $flatpickr-day-disabled-hover-border-color;
      }
    }

    &.selected,
    &.startRange,
    &.endRange {
      &.startRange + .endRange:not(:nth-child(7n+1)) {
        box-shadow: $flatpickr-day-inrange-box-shadow;
      }
    }
  }

  .numInputWrapper {
    span.arrowUp,
    span.arrowDown {
      display: none;
    }

    &:hover {
      background-color: $flatpickr-numinput-wrapper-hover-bg-color;
    }
  }

  .flatpickr-time input {
    color: $flatpickr-day-color;
  }

  .flatpickr-time input:hover,
  .flatpickr-time .flatpickr-am-pm:hover,
  .flatpickr-time input:focus,
  .flatpickr-time .flatpickr-am-pm:focus {
    background-color: $flatpickr-time-hover-bg-color;
  }
}

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback).flatpickr-calendar {
  @include border-start-radius($flatpickr-border-radius);
}

// Borderless
.flatpickr-custom-borderless {
  width: 0 !important;
  min-width: $flatpickr-custom-min-width !important;

  .input-group-text {
    border: 0;
    padding-right: 0 !important;
  }

  .flatpickr-custom-form-control {
    border: none;
    cursor: pointer;
    box-shadow: none;
    padding-right: 0;
    color: $flatpickr-form-control-color;
  }
}

// Position Fix on Mobile
.flatpickr-custom-position-fix-sm-down {
  .flatpickr-calendar {
    left: -100% !important;
  }
}