/*------------------------------------
  Step Inline
------------------------------------*/

.step-inline {
  .step-content-wrapper {
    align-items: center;
  }

  .step-item:last-child {
    .step-title::after {
      display: none;
    }
  }

  .step-title {
    display: inline-block;
  }
}

// Small Devices
@include media-breakpoint-up(sm) {
  .step-sm.step-inline {
    &.step-dashed {
      .step-title::after {
        border-top-style: dashed;
      }
    }

    .step-item {
      overflow: hidden;
    }

    .step-icon {
      margin-bottom: 0;

      &::after {
        display: none;
      }
    }

    .step-title {
      &::after {
        position: absolute;
        top: calc($step-icon-height / 2);
        width: 100%;
        height: calc($step-border-width + $step-padding-x);
        border-top: $step-border-width solid $step-border-color;
        margin-left: $step-padding-x;
        content: "";
      }
    }

    &.step-icon-xs .step-content .step-title::after,
    .step-icon-xs + .step-content .step-title::after {
      top: calc($step-icon-height-xs / 2);
    }

    &.step-icon-sm .step-content .step-title::after,
    .step-icon-sm + .step-content .step-title::after {
      top: calc($step-icon-height-sm / 2);
    }

    &.step-icon-lg .step-content .step-title::after,
    .step-icon-lg + .step-content .step-title::after {
      top: calc($step-icon-height-lg / 2);
    }
  }
}

// Medium Devices
@include media-breakpoint-up(md) {
  .step-md.step-inline {
    &.step-dashed {
      .step-title::after {
        border-top-style: dashed;
      }
    }

    .step-item {
      overflow: hidden;
    }

    .step-icon {
      margin-bottom: 0;

      &::after {
        display: none;
      }
    }

    .step-title {
      &::after {
        position: absolute;
        top: calc($step-icon-height / 2);
        width: 100%;
        height: calc($step-border-width + $step-padding-x);
        border-top: $step-border-width solid $step-border-color;
        margin-left: $step-padding-x;
        content: "";
      }
    }

    &.step-icon-xs .step-content .step-title::after,
    .step-icon-xs + .step-content .step-title::after {
      top: calc($step-icon-height-xs / 2);
    }

    &.step-icon-sm .step-content .step-title::after,
    .step-icon-sm + .step-content .step-title::after {
      top: calc($step-icon-height-sm / 2);
    }

    &.step-icon-lg .step-content .step-title::after,
    .step-icon-lg + .step-content .step-title::after {
      top: calc($step-icon-height-lg / 2);
    }
  }
}

// Large Devices
@include media-breakpoint-up(lg) {
  .step-lg.step-inline {
    &.step-dashed {
      .step-title::after {
        border-top-style: dashed;
      }
    }

    .step-item {
      overflow: hidden;
    }

    .step-icon {
      margin-bottom: 0;

      &::after {
        display: none;
      }
    }

    .step-title {
      &::after {
        position: absolute;
        top: calc($step-icon-height / 2);
        width: 100%;
        height: calc($step-border-width + $step-padding-x);
        border-top: $step-border-width solid $step-border-color;
        margin-left: $step-padding-x;
        content: "";
      }
    }

    &.step-icon-xs .step-content .step-title::after,
    .step-icon-xs + .step-content .step-title::after {
      top: calc($step-icon-height-xs / 2);
    }

    &.step-icon-sm .step-content .step-title::after,
    .step-icon-sm + .step-content .step-title::after {
      top: calc($step-icon-height-sm / 2);
    }

    &.step-icon-lg .step-content .step-title::after,
    .step-icon-lg + .step-content .step-title::after {
      top: calc($step-icon-height-lg / 2);
    }
  }
}

// Extra Large Devices
@include media-breakpoint-up(xl) {
  .step-xl.step-inline {
    &.step-dashed {
      .step-title::after {
        border-top-style: dashed;
      }
    }

    .step-item {
      overflow: hidden;
    }

    .step-icon {
      margin-bottom: 0;

      &::after {
        display: none;
      }
    }

    .step-title {
      &::after {
        position: absolute;
        top: calc($step-icon-height / 2);
        width: 100%;
        height: calc($step-border-width + $step-padding-x);
        border-top: $step-border-width solid $step-border-color;
        margin-left: $step-padding-x;
        content: "";
      }
    }

    &.step-icon-xs .step-content .step-title::after,
    .step-icon-xs + .step-content .step-title::after {
      top: calc($step-icon-height-xs / 2);
    }

    &.step-icon-sm .step-content .step-title::after,
    .step-icon-sm + .step-content .step-title::after {
      top: calc($step-icon-height-sm / 2);
    }

    &.step-icon-lg .step-content .step-title::after,
    .step-icon-lg + .step-content .step-title::after {
      top: calc($step-icon-height-lg / 2);
    }
  }
}