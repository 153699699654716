// Setup
@import '../../../node_modules/bootstrap/scss/functions';

@import 'themes/default';
@import 'user-variables';
@import 'front-dashboard/variables';

@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/maps';
@import '../../../node_modules/bootstrap/scss/utilities';

@import 'front-dashboard/utilities';
@import 'user-utilities';

// Bootstrap core
@import '../../../node_modules/bootstrap/scss/bootstrap';

/*----------------------------------------------------------------------
  * Front - Responsive Website Template version v2.0.0
  * Copyright 2021 Htmlstream
  * Licensed under Bootstrap Themes (https://themes.getbootstrap.com/licenses/)
------------------------------------------------------------------------*/

@import 'front-dashboard/front-dashboard';

// Custom core
@import 'user';