
.libel
{
    margin-right:0.5rem !important;
    color:#8c98a4 !important;
    font-size: 0.875rem;
}
body
{
    font-smooth: auto ;
    -webkit-font-smoothing : antialiased;
}

.up:after
{
    display: inline-flex;
    align-items: center;
    width: 0.7rem;
    height: 0.7rem;
    background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg width='0.7rem' height='0.7rem' viewBox='0 0 292 375' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M131.965 15.38c7.535-8.257 20.535-8.257 28.07 0l102.941 112.813C274.106 140.39 265.453 160 248.941 160H43.059c-16.512 0-25.165-19.61-14.035-31.807L131.965 15.381Z' fill='%2351307c'/%3E%3Cpath d='M160.035 359.619c-7.535 8.258-20.535 8.258-28.07 0L29.024 246.807C17.894 234.61 26.547 215 43.059 215H248.94c16.512 0 25.165 19.61 14.035 31.807L160.035 359.619Z' fill='%23dde1ee'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 0.7rem 0.7rem;
    content: "";
    margin-left: 0.5rem;
    transform: translateY(-50%);
}
.none:after
{
    align-items: center;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='0.7rem' height='0.7rem' viewBox='0 0 292 375' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M131.965 15.38c7.535-8.257 20.535-8.257 28.07 0l102.941 112.813C274.106 140.39 265.453 160 248.941 160H43.059c-16.512 0-25.165-19.61-14.035-31.807L131.965 15.381ZM160.035 359.619c-7.535 8.258-20.535 8.258-28.07 0L29.024 246.807C17.894 234.61 26.547 215 43.059 215H248.94c16.512 0 25.165 19.61 14.035 31.807L160.035 359.619Z' fill='%23dde1ee'/%3E%3C/svg%3E");
    background-position: 100%;
    background-repeat: no-repeat;
    background-size: .7rem .7rem;
    content: "";
    display: inline-flex;
    height: .7rem;
    margin-left: .5rem;
    transform: translateY(-50%);
    width: .7rem;
}
.down:after
{
    align-items: center;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='0.7rem' height='0.7rem' viewBox='0 0 292 375' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M131.965 15.38c7.535-8.257 20.535-8.257 28.07 0l102.941 112.813C274.106 140.39 265.453 160 248.941 160H43.059c-16.512 0-25.165-19.61-14.035-31.807L131.965 15.381Z' fill='%23dde1ee'/%3E%3Cpath d='M160.035 359.619c-7.535 8.258-20.535 8.258-28.07 0L29.024 246.807C17.894 234.61 26.547 215 43.059 215H248.94c16.512 0 25.165 19.61 14.035 31.807L160.035 359.619Z' fill='%2351307c'/%3E%3C/svg%3E");
    background-position: 100%;
    background-repeat: no-repeat;
    background-size: .7rem .7rem;
    content: "";
    display: inline-flex;
    height: .7rem;
    margin-left: .5rem;
    transform: translateY(-50%);
    width: .7rem;
}

.col-form-label
{
    color:#000000;
    /*font-weight: bold;*/
}



dd
{
   margin-bottom: 0.2rem !important;
}

.card .card-table > tbody > tr:first-child > td
{
    padding-top:0.75rem !important;
}

.list-py-1 > li:not(:last-child) {
    padding-bottom: 0.2rem !important;
}

.cl
{
    display:block;

}
.cv
{
    display:block;
    color:#000000;

}

.cke_chrome
{
    border:none !important;

}

.cke_editable
{
    padding:20px;
}
