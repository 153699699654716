/*------------------------------------
  Step Avatar
------------------------------------*/

.step {
  .step-avatar {
    font-size: $step-avatar-font-size;
    font-weight: $step-avatar-font-weight;
    width: $step-avatar-width;
    height: $step-avatar-height;
    @include border-radius($step-avatar-border-radius);
    margin-right: $step-avatar-margin-x;
  }

  .step-avatar-img {
    max-width: 100%;
    height: auto;
    @include border-radius($step-avatar-border-radius);
  }

  .step-avatar::after {
    position: absolute;
    top: calc($step-avatar-height + ($step-padding-x / 2));
    left: calc($step-avatar-width / 2);
    height: calc(100% - #{$step-avatar-height + calc(($step-padding-x / 2)) - $step-padding-x});
    border-left: $step-border-width solid $step-border-color;
    content: "";
  }
}