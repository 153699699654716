/*------------------------------------
  Offcanvas
------------------------------------*/

.offcanvas-header {
  padding-top: $offcanvas-header-padding-y;
  padding-bottom: $offcanvas-header-padding-y;
  border-bottom: $offcanvas-border-width solid $offcanvas-header-border-bottom-color;
}

.offcanvas-navbar-search,
.offcanvas.offcanvas-navbar-search {
  bottom: auto;
  height: auto;
}

.offcanvas-footer {
  padding: $offcanvas-footer-padding-y $offcanvas-padding-x;
  border-top: $offcanvas-border-width solid $offcanvas-footer-border-bottom-color;
}